/**
 * Custom dimension indexes.
 * You find indexes for Custom Dimensions in Google Analytics dashboard.
 * @type {Object}
 */
export const customDimensions = {
  LEADS_PRODUCTAREA: "cd41",
  ERROR_MESSAGE: "cd23",
  GROUPID: "cd24",
  INTCMP: "cd33",
  LOGGED_IN_ACCOUNT: "cd26", // value MB
  AGREEMENT_TYPE: "cd94",
  IMPERSONATION: "cd95",
  SEARCH_FILTER: "cd76",
  FEATURE_HIGHLIGHT: "cd29",
  PROMO_ID: "promo1id",
  PROMO_NAME: "promo1nm",
  PROMO_CREATIVE: "promo1cr",
  PROMO_POSITION: "promo1ps",
};

/**
 * Constants
 * All constants for eventData should be placed here so that we all use the same ones. Add new when needed.
 * @type {Object}
 */
export const action = {
  LANDED: "Landed",
  OPEN: "Open",
  HIDE: "Hide",
  MOVE: "Move",
  ADD: "Add",
  INITIATED: "Initiated",
  INITIATE: "Initiate",
  VERIFY: "Verify",
  COMPLETED: "Completed",
  ERROR: "Error",
  CLICK: "Clicked",
  APP_LINK_CLICK: "Application Link Clicked",
  APP_DRAWER_CLICK: "Application button clicked",
  SETTINGS_DRAWER_CLICK: "Settings button clicked",
  ENGLISH_LANGUAGE_CLICK: "English language clicked",
  SWEDISH_LANGUAGE_CLICK: "Swedish language clicked",
  SCOPE_SELECTOR_CHANGE: "Scope changed",
  SELECTED: "Selected",
  FILTER: "Filter",
  SORT: "Sort",
  DOWNLOAD: "Download",
  DOWNLOAD_EXCEL: "download_excel",
  UPLOAD_EXCEL: "upload_excel",
  REGISTER_EXCEL: "register_excel",
  FETCH: "Fetch",
  CLEAR: "Clear",
  LEADS_FAIL: "Fail",
  STEP_1: "Step 1",
  STEP_2: "Step 2",
  STEP_3: "Step 3",
  CALL_INVOICE_CACHE: "Call invoice cache",
  PRODUCT_LIST: "ProductList",
  PRODUCT_CLICK: "ProductClick",
  PRODUCT_DETAIL: "ProductDetail",
  PRODUCT_ADD_TO_CART: "ProductAddToCart",
  PRODUCT_REMOVE_FROM_CART: "ProductRemoveFromCart",
  PRODUCT_CHANGE_IN_CART: "ProductChangeInCart",
  OPEN_CONFIG: "OpenConfig",
  OPEN_MODAL: "OpenModal",
  TO_CHECKOUT: "Checkout",
  CHECKOUT_STEP: "CheckoutStep",
  ORDER_COMPLETED: "OrderCompleted",
  VIEW: "View",
  START_COLUMN: "Start column",
  ADD_COLUMN: "Add column",
  REMOVE_COLUMN: "Remove column",
  EXPAND: "Expand",
  MINIMIZE: "Minimize",
  PAGINATE: "Paginate",
  SEARCH: "Search",
  EXPORT_INITIATED: "Export initiated",
  SEARCH_RESULTS: "Search results",
  SHOW: "Show",
  SELECT: "Select",
  INTERACT: "Interact",
  LINK_CLICK: "Link_click",
  SUBMIT: "Submit",
  APPLIED: "Applied",
  SEND: "Send",
};

export const category = {
  LEADS: "Leads",
  INVOICES: "Fakturor",
  INVOICE_ONBOARDING_MODAL: "Invoice_onboarding_modal",
  INVOICE_ACCOUNT_ONBOARDING_MODAL: "Invoice_account_onboarding_modal",
  DOWNLOAD_SUBSCRIPTION_LIST: "Produkter_Tjänster_Ladda_ned",
  DOWNLOAD_USERS_LIST: "Användare_Exportera",
  DOWNLOAD_SURVEILLANCE_ALARM: "Övervakning_Larm_Exportera",
  DOWNLOAD_SURVEILLANCE_TRAFFIC: "Trafiköversikt_Exportera",
  MOBILE_ENGAGEMENT: "Hantera_Mobiltelefoni",
  NEWS_MODAL: "Nyhetsmodal",
  MSPA_MIGRATE_MODAL: "MSPA_Migrering_Modal",
  ENDUSER_MODAL: "Slutanvändare_modal",
  COMPANY_SETTINGS: "Företagsinställningar",
  MANAGE: "Hantera",
  MY_BUNDLE: "MB_Konfiguratorn",
  OPEN_MOBILE_SUBSCRIPTION: "Öppna_Mobilt_Abonnemang",
  REGISTER: "Registrera_mobil_slutanvändare",
  REQUEST_ACCESS: "Ansökningar",
  REMIND_USERNAME: "Glömt_användarnamn",
  CORPORATE_DATA_POOL: "Telia_datapool",
  INVOICE_ACCOUNTS: "Fakturakonton",
  INVOICE_ACCOUNT_DETAILS: "Fakturakonton_Detaljer",
  INVOICE: "Faktura",
  USERS: "Användare",
  USERS_TABLE: "Användare_Lista",
  INVITES_TABLE: "Inbjudningar_Lista",
  INVITE: "Inbjudan",
  MOBILE_END_USERS_TABLE: "Min_Jobbmobil_Lista",
  BOOKMARKS: "Bokmärken",
  SURVEILLANCE_ALARM: "Övervakning_Larm",
  SURVEILLANCE_TRAFFIC: "Trafiköversikt",
  PROFILE: "Min profil",
  GROUP_SWITCH: "Företags kugghjul inställningar",
  BESTALL: "Produkter_Tjänster_Beställ",
  WOV_NOTE: "WOV_Abonnemangs_Noteringar",
  WOV_DELIVERY_NOTE: "WOV_Leverans_Noteringar",
  SUBSCRIPTION_ALIAS: "WOV_Abonnemangs_Alias",
  VPN_ALIAS: "WOV_VPN_Alias",
  WOV_CONTACT: "WOV_Abonnemangs_Kontakt",
  AUTH_LEVEL_MODAL: "Auth_level_modal",
  SSO_SERVICES: "SSO_Services",
  WOV_NOTIFICATION_CONTACT: "WOV_Meddelandetjänster_Kontaktperson",
  WOV_NOTIFICATION_CREATE: "WOV_Meddelandetjänster_Ny_Meddelandetjänst",
  WOV_NOTIFICATION_EDIT: "WOV_Meddelandetjänster_Redigera_Meddelandetjänst",
  RESET_PASSWORD: "Byt lösenord",
  USAGE_HISTORY: "Datahistorik (PRAG)",
  ADVANCED_EXPORT_DATACOM: "Avancerad Export Datakom",
  SDW_ICON_PANEL: "SDW_Icon_Panel",
  LOGIN: "Inloggning",
  SALESFLOW: "Salesflow",
  START_PAGE: "Startsida",
  START_PAGE_MODULE: "Startsida_Modul",
  SALES_MFA_RAM: "Sales_MFA_RAM",
  MANAGEFLOW: "Manageflow",
  Navigation: "Navigation",
  RECOMMENDATIONS: "MB_Rekommendationer",
  MANAGE_SUBSCRIPTION: "Hantera",
  PREVIEW_INVOICE_DRAWER: "Förhandsgranska fakturan",
  ONBOARDING: "Onboarding",
  REPORTS: "Beståndsrapporter",
  CORE_WEB_VITALS: "coreWebVitals",
  SUBSCRIPTION_SEARCH: "Abonnemangssök",
  ACCORDION: "Accordion",
  MB_INLOGGNING: "MB_Inloggning",
  SUPPORT_REQUEST: "Support_request",
  VIDEO_START: "video_start",
  REGISTER_ACCOUNT: "Register_account",
  IDENTIFY: "Identify",
  MB_COMPANY_SELECTOR: "MB_Company_Selector",
  SUPPORT_CASEDETAILS: "Support_CaseDetails",
  ACCOUNT_INVITATION: "account_invitation",
};

export const label = {
  CREATE_BILLING_ACCOUNT: "Skapa_fakturakonto",
  GO_TO_OLD_STARTPAGE: "Gå till gamla startsidan",
  CLOSE_MANAGE_MODULES: "Stäng hantera moduler",
  FAKTURAKONTO_SEARCH: "fakturakonto_Search",
  FAKTURAKONTO_MOVE: "Byt_fakturakonto",
  FAKTURAKONTO_MOVE_NEW: "Byt_till_nytt_fakturakonto",
  FAKTURA_POPUP: "Popup",
  OPEN: "Opened",
  NEXT: "Next",
  PREVIOUS: "Previous",
  CLOSED: "Closed",
  TERMINATE_SUBSCRIPTION: "Avsluta_abonnemang",
  UPDATE_MOBILE_REFERENCE_AND_USERNAME: "Uppdatera_användare_och_referens",
  TOUCHPOINT: "Touch_Point",
  TOUCHPOINT_NEW: "touchpoint_plus_new_add_users",
  SHOW_RECOMMENDATION: "Visa_rekommendation",
  NUMBERMIGRATION: "Nummerflytt",
  CHANGE_REFERENCE: "AliasEllerNamn_Ändra",
  UPDATE_CONTACT: "Update contact",
  OPEN_MOVE_SUBSCRIPTION_DRAWER: "Öppna_modal_byta_fakturakonto",
  OPEN_EDIT_ACCOUNT_DRAWER: "Öppna_modal_ändra_fakturakonto_detaljer",
  CLOSE_DRAWER_GA_LABEL: "Closed_modal",
  UPDATE_ADDRESS: "Uppdatera_fakturaadress",
  CHANGE_DELIVERY_METHOD_EMAIL: "Ändra_leveransmetod_till_E-post",
  UPDATE_DELIVERY_METHOD_POSTAL: "Uppdatera_leveranssätt_till_Brev",
  UPDATE_DELIVERY_METHOD_EDI: "Uppdatera_reference_till_Edi",
  OPEN_DRAWER_E_POSTMOTTAGARE: "Öppna_modal_e-postmottagare",
  CLOSED_DRAWER_E_POSTMOTTAGARE: "Stängd_modal_e-postmottagare",
  OPEN_PREVIEW_INVOICE_DRAWER: "Öppna_Modal_Förhandsgranska_Fakturan",
  CLOSED_PREVIEW_INVOICE_DRAWER: "Stängd_Modal_Förhandsgranska_Fakturan",
  SORTING_PREVIEW_INVOICE: "Sorting_",
  DROPDOWN_MORE_INFORMATION_ARROW_PREVIEW_INVOICE: "Vald_Abonnemang_Mer_Information",
  ERROR_FETCHING_PREVIEW_INVOICE: "Faktura_Information_Inte_Tillgängig",
  LADDA_NER: "Ladda ner",
  COMPONENT_OPEN: "Component open",
  CANCEL: "Cancel",
  NEW_SUPPORT_REQUEST: "new_support_request",
  ADDITIONAL_SUPPORT_REQUEST: "additional_support_request",
  YES: "yes",
  NO: "no",
  ATTACH_FILE: "attach_file",
  CONTACT_INFO: "contact_info",
  CHOOSE_ORGANISATION: "choose_organization",
  EDIT: "edit",
  SAVE: "save",
  ADD_EMAIL: "add_email",
  ACCOUNT_CREATION: "mb_account_creation",
  BANK_ID_CONNECTION: "bank_id_connection",
  BANK_ID: "bank_id",
  COMPANY_ALREADY_EXISTS: "company_already_exists",
  FAIL: "fail",
  COMPANY_NOT_FOUND: "company_not_found",
  CHOSEN_COMPANY: "chosen_company",
  CHOSEN_COMPANY_SEARCHED: "chosen_company_searched",
  CLOSED_ERROR: "closed",
  RESTRICTED_LOGIN_ERROR: "restricted_login",
  UNEXPECTED_ERROR: "unexpected",
  LOGIN_WITH_USERNAME: "Logga in med användarnamn",
  GUEST_ACCOUNT: "guest_account",
  CONTINUE_TO_CHECKOUT: "Fortsätt till kassan",
  AUTHORIZED_ORDERER: "Hur blir jag behörig beställare",
  EXPORT_USERLIST: "export_userlist",
  TAB_USER: "tab_users",
  TAB_INVITES: "tab_invites",
  INVITE_USER: "invite_user",
  VERIFY_ORGANISATION: "verify_organisation",
  CHANGE_ORGANISATION: "change_organisation",
  ORDER_BROADBAND: "order-broadband",
  SUBSCRIPTION_ALREADY_IN_CART: "SubscriptionAlreadyInCart",
  NEW_MESSAGE: "new_message",
  ONE_TIME_CODE: "one_time_code",
  LOGIN: "login",
};
