import { analytics } from "@telia/b2x-common-frontend-analytics";
import { isTeliaAdmin } from "@telia/b2b-logged-in-service";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { onCLS, onFID, onLCP, onINP } from "web-vitals";
import type { Metric } from "web-vitals";

import { getTeliaAnalyticsEventData } from "./util";
import { customDimensions, category, action, label } from "./data";
import { Action, Category, CustomDimension, Label, TrackOptions, Value } from "./interfaces";
import { onceConsented } from "@telia/b2x-cookie-consent";

export { customDimensions, category, action, label };

export * from "./frameworkUtils";

declare global {
  interface Window {
    teliaAnalytics: {
      event: (...parameters) => void;
      q: Array<unknown>;
      pageview: (...parameters: unknown[]) => void;
    };
  }
}

export const writeProductData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  payload: any,
  product: {
    id: string;
    name: string;
    category: string;
    subCategory?: string;
    brand?: string | undefined;
    productCode: string;
    totalUnitFee: string;
  },
  index: string | number,
  quantity: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
): any => {
  if (product) {
    return {
      ...payload,
      [`pr${index}id`]: product.id,
      [`pr${index}nm`]: product.name,
      [`pr${index}ca`]: getConcatenatedCategories(product.category, product.subCategory),
      [`pr${index}br`]: product.brand || "Telia",
      [`pr${index}va`]: product.productCode,
      [`pr${index}pr`]: product.totalUnitFee,
      [`pr${index}qt`]: isNaN(quantity) ? 1 : quantity,
    };
  }
  return payload;
};

export const trackEvent = async (
  category: Category,
  action: Action,
  label: Label,
  value?: Value,
  customDimensions?: CustomDimension[]
): Promise<void> => {
  try {
    await track({
      category: category && "MB_" + category,
      action,
      label,
      value,
      customDimensions,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
export const trackEventOpenPage = async (
  category: Category,
  action: Action,
  label: Label,
  value?: Value,
  customDimensions?: CustomDimension[]
): Promise<void> => {
  if (!window.teliaAnalytics) {
    // eslint-disable-next-line no-console
    console.log("teliaAnalytics not loaded on window object");
    return;
  }

  if (!category || !action || !label) {
    return;
  }

  const options: TrackOptions = {
    category: category,
    label: label,
    action: action,
    value: value,
    customDimensions: customDimensions,
  };

  sendAnalyticsEvent(options);
};

export const trackEventWithoutPrefix = async (
  category: string,
  action: string,
  label: string,
  value?: string | null,
  customDimensions?: CustomDimension[]
): Promise<void> => {
  try {
    await track({ category, action, label, value, customDimensions });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

const track = async (options: TrackOptions): Promise<void> => {
  if (!window.teliaAnalytics) {
    // eslint-disable-next-line no-console
    console.log("teliaAnalytics not loaded on window object");
    return;
  }

  try {
    if (!options.category || !options.action || !options.label || (await isTeliaAdmin())) {
      return;
    }
  } catch {
    /**
     * When an error is caught it's likely that we're missing a scope. Therefore, when isTeliaAdmin
     * fails it's unlikely that it would've returned true. Hence we just continue.
     */
  }

  let scopeId: string | undefined;
  try {
    scopeId = await getScopeIdOrThrow();
  } catch (error) {
    scopeId = undefined;
  }
  sendAnalyticsEvent(options, scopeId);
};

const sendAnalyticsEvent = (options: TrackOptions, scopeId?: string) => {
  const teliaAnalyticsEventData = getTeliaAnalyticsEventData(options, scopeId);
  try {
    window.teliaAnalytics.event(
      // Category
      teliaAnalyticsEventData.parameters.ec,
      // Action
      teliaAnalyticsEventData.parameters.ea,
      // Label
      teliaAnalyticsEventData.parameters.el,
      // Value
      teliaAnalyticsEventData.parameters.evAndCd
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("error in teliaAnalytics ", error);
  }
};

export const getConcatenatedCategories = (category: Category, subCategory?: Category): string => {
  return `${category}${subCategory ? `/${subCategory}` : ""}`;
};

export function trackWebVital(metric: Metric) {
  const valueRounded = Math.round(metric.name === "CLS" ? metric.value * 1000 : metric.value);

  trackEventWithoutPrefix(category.CORE_WEB_VITALS, metric.name, metric.id, `${valueRounded}`);
}

// single-spa exports
export async function bootstrap(): Promise<void> {
  onceConsented("C0002", "Enable Google Analytics to track events").then(analytics().process);
  return Promise.resolve();
}

export async function mount(): Promise<void> {
  window.addEventListener("single-spa:routing-event", onSingleSpaRoutingEvent);
  return Promise.resolve();
}

function onSingleSpaRoutingEvent(event): void {
  const { oldUrl, newUrl } = (event as CustomEvent).detail;
  const newUrlPathname = new URL(newUrl).pathname;
  const windowLocation = window.location.href;
  const windowLocationPathname = new URL(windowLocation).pathname;

  if (oldUrl !== newUrl) {
    window.teliaAnalytics.pageview(windowLocationPathname, 0, {
      cd16: newUrlPathname,
    });
  }

  onCLS(trackWebVital);
  onFID(trackWebVital);
  onLCP(trackWebVital);
  onINP(trackWebVital);
}

export async function unmount(): Promise<void> {
  window.removeEventListener("single-spa:routing-event", onSingleSpaRoutingEvent);
  return Promise.resolve();
}
