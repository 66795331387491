import { TrackOptions, EventData } from "./interfaces";

import { customDimensions } from "./data";

const getParameterFromUrl = (parameter: string, url: string) => {
  const reg = new RegExp(`[?&]${parameter}=([^&#]*)`, "i");
  const value = reg.exec(url);
  return value && value[1];
};

export const getTeliaAnalyticsEventData = (options: TrackOptions, scopeId?: string): EventData => {
  const getValue = (v) => (isNaN(parseInt(v + "")) ? "0" : parseInt(v + "") + "");

  const intcmpTagValue = getParameterFromUrl("intcmp", document.URL);
  const eventData = {
    parameters: {
      ec: options.category,
      ea: options.action,
      el: options.label,
      evAndCd: {
        ev: getValue(options.value),
      },
    },
  };

  if (!options.customDimensions) {
    options.customDimensions = [];
  }

  if (scopeId) {
    eventData.parameters.evAndCd[customDimensions.GROUPID] = scopeId;
  }

  if (intcmpTagValue) {
    eventData.parameters.evAndCd[customDimensions.INTCMP] = intcmpTagValue;
  }

  eventData.parameters.evAndCd = Object.assign(
    {},
    eventData.parameters.evAndCd,
    options.customDimensions.reduce((result, dimension) => {
      result[dimension.type] = dimension.value;
      return result;
    }, {})
  );

  return eventData;
};
