import * as analytics from "../b2b-web-analytics-wrapper";

export default {
  methods: {
    trackOnboardingOpen(): void {
      analytics.trackEvent(
        analytics.category.ONBOARDING,
        analytics.action.INITIATED,
        analytics.label.COMPONENT_OPEN
      );
    },
    trackOnboardingClose(activeStep: number, numberOfSteps: number): void {
      analytics.trackEvent(
        analytics.category.ONBOARDING,
        analytics.action.COMPLETED,
        `Closed on step ${activeStep} of ${numberOfSteps}`
      );
    },
    trackOnboardingNextStep(): void {
      analytics.trackEvent(
        analytics.category.ONBOARDING,
        analytics.action.CLICK,
        analytics.label.NEXT
      );
    },
    trackOnboardingPreviousStep(): void {
      analytics.trackEvent(
        analytics.category.ONBOARDING,
        analytics.action.CLICK,
        analytics.label.PREVIOUS
      );
    },
  },
};
